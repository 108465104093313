import React, { useMemo } from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';

import PropTypes from 'prop-types';
import SEO from 'components/shared/SEO';
import BlogPosts from 'components/BlogPosts/Posts';
import slugify from 'slugify';

const Main = styled.main`
  min-height: calc(100vh - 75px);
  padding-top: 160px;
  line-height: 1.5;
`;

const Header = styled.h1`
  width: 100%;
  text-align: center;
  font-weight: ${({ theme }) => theme.bold};
  font-size: ${({ theme }) => theme.fontSize.xl};
  &:after {
    content: '';
    display: block;
    height: 1px;
    width: 65px;
    background: rgb(112, 112, 112);
    margin: 25px auto 30px;
  }
`;

const NoPostsInfo = styled.h6`
  margin: 0 auto;
  font-size: ${({ theme }) => theme.fontSize.lg};
  text-align: center;
`;

const BlogPage = ({ data }) => {
  const blogPostsNodes = data.allWpBlogArticle.edges;

  const blogPosts = useMemo(
    () =>
      blogPostsNodes.map(({ node }) => {
        const {
          date,
          slug,
          title,
          blog: { zajawka, autorWpisu },
        } = node;

        return {
          id: slug,
          title,
          author: autorWpisu,
          description: zajawka,
          date,
          link: `/blog/${slugify(title, { lower: true })}`,
        };
      }),
    []
  );

  return (
    <>
      <Main>
        <SEO title="Blog" />
        <Header dangerouslySetInnerHTML={{ __html: 'Blog' }} />
        <BlogPosts posts={blogPosts} />
        {blogPosts.length < 1 && <NoPostsInfo> Brak postów</NoPostsInfo>}
      </Main>
    </>
  );
};

export const query = graphql`
  query BlogPostsQuery {
    allWpBlogArticle(
      filter: { blog: { jezyk: { eq: "polski" } } }
      sort: { fields: date, order: DESC }
    ) {
      edges {
        node {
          blog {
            jezyk
            zajawka
            autorWpisu
          }
          slug
          title
          date(formatString: "DD-MM-YYYY")
        }
      }
    }
  }
`;

BlogPage.propTypes = {
  data: PropTypes.objectOf(PropTypes.object),
};

export default BlogPage;
